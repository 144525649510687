<template>
  <div id="CoachAction" class="coachAction">
    <div class="banner-con">
      <div class="top-left-con">
        <div class="line-one" v-visible="false"></div>
        <div class="line-two" v-visible="false"></div>
        <div class="line-three"></div>
        <p class="bg-tit2">
          {{ $t('CoachAction.title') }}
          <span>
            <img style="width: 90px" src="../assets/img/2-Icons/icons_connect-wh.svg" alt="" />
          </span>
        </p>
      </div>
      <swiper :options="swiperOption" class="swiper" ref="mySwiper">
        <swiper-slide v-for="(item, index) in swiperImg" :key="index" width="150px" class="swiper-item1">
          <div class="box">
            <img class="img" :src="item.img" alt="" />
          </div>
          <!-- <div class="top-right-con"></div> -->
          <div class="bottom-left-con"></div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" />
      </swiper>
    </div>
    <div class="center-background">
      <div class="center-container" style="max-width: 1200px; margin: 0 auto">
        <div class="info-title">
          <!-- <div class="info-tit">
                        {{ $t("HomeNew.aboutinfo") }}
                    </div> -->
          <br />
          <div class="info1">
            {{ $t('HomeNew.inform1') }}
          </div>
          <br />
          <div class="info2">
            {{ $t('HomeNew.inform2') }}
          </div>
          <br />
          <div class="info3">
            {{ $t('HomeNew.inform3') }}
          </div>
        </div>

        <div class="course-video">
          <div class="left">
            <div class="img-box">
              <img class="img-bg" src="../assets/img/0-Banner/DSC00250.jpg" alt="" />
            </div>
            <div class="tit-box" @click="goPush('/coach-action/sharing')">
              <img class="img" src="../assets/images/button-course.png" alt="" />
               <div v-if="$i18n.locale == 'cn'" >
              <p class="text1">{{ $t('CoachAction.pic1') }}</p></div>
              <div v-else><p class="text2">{{ $t('CoachAction.pic1') }}</p></div></div>
           
            
            <div class="bottom-con"></div>
            <div class="mantle"></div>
            <div class="click-mantle"></div>
          </div>
          <div class="right">
            <div class="img-box">
              <img class="img-bg" src="../assets/img/0-Banner/DSC01030.jpg" alt="" />
            </div>
            <div class="tit-box" @click="goPush('/coach-action/coaching-tools')">
              <img class="img" src="../assets/images/button-video.png" alt="" />
              <div v-if="$i18n.locale == 'cn'" >
              <p class="text1">{{ $t('CoachAction.pic2') }}</p></div>
              <div v-else ><p class="text2">{{ $t('CoachAction.pic2') }}</p></div>

         
            </div>
            <div class="bottom-con"></div>
            <div class="mantle"></div>
            <div class="click-mantle">
              <!--<img
            v-if="locale"
            src="../../assets/images/svg/button-2.svg"
            alt=""
          />
          <img
            v-else
            src="../../assets/images/button-活動影片-ENG.png"
            alt=""
          />
          <p class="more">
            {{ $t("Inspire.more") }}<i class="el-icon-arrow-right"></i>
          </p>-->
            </div>
          </div>
        </div>

        <div class="video">
          <div class="video-title">
            <div class="video-title1">
              {{ $t('CoachAction.about') }}
            </div>
          </div>

          <!--div class="video-pic"></div -->

          <iframe
            width="800"
            height="480"
            src="https://www.youtube.com/embed/JR8oaXzhdb8"
            title="運動教學資源庫  課程編排冇難度！"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoachAction',
  data() {
    return {
      swiperOption: {
        loop: true,
        speed: 1500,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      swiperImg: [
        {
          img: require('../assets/img/0-Banner/coach-action-banner.png'),
        },
      ],
      Lists: [
        {
          img: require('../assets/img/0-Banner/DSC00250.jpg'),
          text: this.$t('CoachAction.pic1'),

          jump: '/coach-action/sharing',
        },
        {
          img: require('../assets/img/0-Banner/DSC01030.jpg'),
          text: this.$t('CoachAction.pic2'),

          jump: '/coach-action/coaching-tools',
        },
      ],
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  mounted() {},
  methods: {
    goPush(page, index) {
      this.$router.push(page)
    },
  },
}
</script>
<style lang="scss" scoped>
.line {
  background-color: #d2d2d2;
  height: 1px;
  margin: 60px 10%;
}
.center-background {
  background: url('../assets/img/bg-blue.jpg');
}
.center-container {
  max-width: 1200px;
  margin: 0 auto;
}
.info-title {
  padding-top: 100px;
  margin: 0 5%;
  font-size: 26px;
  text-align: left;
  .info-tit {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
  }
}
.saved {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
}
.coachAction {
  .banner-con {
    position: relative;
    .top-left-con {
      width: 62%;
      height: 500px;
      background: #28327b;
      position: absolute;
      top: 0;
      left: 0;
      transform: skew(-15deg) translateX(-9%);
      z-index: 2;
      .line-one {
        width: 338px;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 24%;
        right: 2%;
      }
      .line-two {
        width: 120px;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 42%;
        right: 2%;
      }
      .bg-tit1 {
        margin-top: 150px;
        height: 36px;
        font-size: 32px !important;
        font-weight: 400;
        line-height: 42px;
        color: #ffffff;
        transform: skew(13deg);
      }
      .bg-tit2 {
        margin: 0 auto;
        margin-top: 150px;
        font-size: 75px;
        font-weight: bold;
        line-height: 75px;
        color: #ffffff;
        font-size: 70px !important;
        opacity: 1;
        transform: skew(13deg);
      }
      .more {
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: #fff;
        transform: skew(15deg);
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .swiper {
      height: 620px;
      .swiper-item1 {
        position: relative;
        overflow: hidden;
        .box {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 60%;
          overflow: hidden;
          transform: skew(-15deg) translateX(12%);
          .img {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            transform: skew(15deg) translateX(-9%);
            object-fit: cover;
          }
        }
        .top-right-con {
          width: 40%;
          height: 9.1%;
          background: #f15922;
          position: absolute;
          top: 3%;
          right: 0;
          transform: skew(-15deg) translateX(2.5%);
        }
        .bottom-left-con {
          width: 40%;
          height: 15%;
          background: #f15922;
          position: absolute;
          bottom: 5%;
          left: 0;
          transform: skew(-15deg) translateX(-3%);
        }
      }
    }
  }

  .course-video {
    width: 100%;
    // height: 380px;
    margin: 1% auto 1%;
    display: flex;
    position: relative;
    // background: red;
    .left {
      position: absolute;
      left: 0;
      width: 52%;
      height: 511px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      transform: translateX(1%);
      .img-box {
        display: flex;
        width: 100%;
        height: 511px;
        object-fit: cover;
        transform: skew(-6deg) translateX(-4%);
        overflow: hidden;
        .img-bg {
          width: 104%;
          height: 511px;
          object-fit: cover;
          transform: skew(6deg) translateX(4%);
        }
      }
      .bottom-con {
        position: absolute;
        bottom: 0;
        width: 106%;
        height: 15px;
        background: #28327b;
        transform: skew(-8deg) translateX(-9.3%);
        opacity: 1;
      }
      .tit-box {
        cursor: pointer;
        z-index: 5;
        width: 40%;
        position: absolute;
        
        .img {
          width: 100%;
        }
        .text1{

          position: absolute;
          top: 20%;
          left: 23%;
       
         text-align: center;
  

          
          color: #fff;
          // font-family: "MHeiHK-Xbold";
          font-size: 26px;
          font-weight: bold;

        }
        .text2{
          text-align: center;
           position: absolute;
          top: 20%;
          left: 10%;
          color: #fff;
          // font-family: "MHeiHK-Xbold";
          font-size: 26px;
          font-weight: bold;
          letter-spacing: 0px;
        }
     
      }
      .mantle {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #28327b;
        transform: skew(-6deg) translateX(-4%);
        transition: all ease 0.6s;
      }
      .click-mantle {
        opacity: 0;
        width: 55%;
        position: absolute;
        top: 40%;
        z-index: 1;
        transition: all ease 0.6s;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        .more {
          color: #fff;
          font-weight: bold;
        }
      }
      &:hover {
        .mantle {
          opacity: 0.6;
        }
        .click-mantle {
          opacity: 1;
        }
        /*.tit-box {
          display: none;
        }*/
      }
    }
    .right {
      position: absolute;
      right: 0;
      width: 52%;
      height: 511px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      transform: translateX(3%);
      .img-box {
        display: flex;
        width: 99%;
        height: 511px;
        transform: skew(-6deg) translateX(3%);
        overflow: hidden;
        .img-bg {
          width: 100%;
          height: 511px;
          transform: skew(6deg) translateX(-4%);
        }
      }
      .bottom-con {
        position: absolute;
        bottom: 0;
        width: 105%;
        height: 15px;
        background: #f15922;
        transform: skew(-8deg) translateX(3%);
        opacity: 1;
      }
      .tit-box {
        z-index: 5;
        cursor: pointer;
        width: 40%;
        position: absolute;
        .img {
          width: 100%;
        }
        p {
          position: absolute;
          top: 20%;
          left: 33%;
          color: #fff;
          // font-family: "MHeiHK-Xbold";
          font-size: 26px;
          font-weight: bold;
          letter-spacing: 0px;
        }
        .text2{
          text-align: center;
           position: absolute;
          top: 20%;
          left: 13%;
          color: #fff;
          // font-family: "MHeiHK-Xbold";
          font-size: 26px;
          font-weight: bold;
          letter-spacing: 0px;
        }
      }
      .mantle {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #f15922;
        transform: skew(-6deg) translateX(3.5%);
        transition: all ease 0.6s;
      }
      .click-mantle {
        opacity: 0;
        width: 55%;
        position: absolute;
        top: 40%;
        z-index: 1;
        transition: all ease 0.6s;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        .more {
          color: #fff;
        }
      }
      &:hover {
        .mantle {
          opacity: 0.6;
        }
        .click-mantle {
          opacity: 1;
        }
      }
    }
  }
  .tit1 {
    font-size: 26px;
    margin: 3% auto;
    width: 80%;
    letter-spacing: 2px;
    text-align: center;
  }
  .tit2 {
    width: 80%;
    font-size: 30px;
    color: #f15922;
    margin: 3% auto;
    p {
      text-align: left;
      margin: 20px 0;
    }
    p:nth-child(1) {
      text-align: center;
      font-weight: bold;
    }
  }
  .list-scroll {
    margin-top: 50px;
    .list-bg {
      width: 60%;
      margin: 0 auto 100px auto;
      display: flex;
      .list-items {
        // width: 22%;
        width: 500px;
        // box-shadow: 0px 0px 30px #D1D4EB;
        margin: 0 1.5%;
        position: relative;
        cursor: pointer;
        .list-img {
          height: 555px;
          // transform: skew(-6deg);
          overflow: hidden;
          position: relative;
          .coming-soon-container {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .soon {
            position: absolute;
            color: white;
            backdrop-filter: blur(5px);
            // padding: 10px;
            text-align: center;
            font-size: 50px;
            font-weight: bold;
          }

          .img-box {
            width: 100%;
            .homepage-img {
              width: 100%;
              height: 450px;
              object-fit: cover;
              margin: 60px 0 0 0;
            }
          }
          .title {
            width: 100%;
            height: 60px;
            line-height: 60px;
            position: absolute;
            top: 0%;
            opacity: 1;
            color: #fff;
            font-size: 33px;
            font-weight: bold;
            background: #28327b;
            text-align: center;
            transition: all ease 0.6s;
          }
          .more_bg {
            width: 100%;
            height: 100px;
            // line-height: 100px;
            color: white;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 0px;
            background: rgba(40, 50, 123, 1);
            position: absolute;
            bottom: 0;
            display: flex;
            align-items: center;
            .more {
              display: flex;
              align-items: center;
              text-align: left;
              margin-left: 6%;
              width: 90%;
              justify-content: space-between;
            }
          }
          .more_bg:hover {
            background: #f15922;
          }
        }
        .item-drow {
          color: #fff;
          padding: 20px;
          transform: translateX(-29px);
          background: rgba(40, 50, 123, 0.7);
          // background: rgba(186, 186, 207, 0.082);
          display: none;
          .content {
            text-align: left;
            .txt {
              font-size: 18px;
              letter-spacing: 0px;
            }
            .txt:nth-child(1) {
              height: 13%;
              font-size: 22px;
              font-weight: bold;
              display: flex;
              white-space: pre;
              align-items: center;
              justify-content: center;
              text-align: center;
            }
            .txt:nth-child(2) {
              font-size: 20px;
              margin-top: 8px;
              font-weight: bold;
              //white-space: pre;
            }
            .txt:nth-child(4) {
              margin-bottom: 15px;
            }
            .txt:nth-child(5) {
              font-weight: bold;
              //white-space: pre;
            }
            .img-box {
              text-align: center;
              .img {
                width: 65%;
              }
            }
          }
        }
      }
      .list-items:hover .item-drow {
        display: block;
        animation: hide 0.5s;
      }
      @keyframes hide {
        0% {
          opacity: 0;
        }
        40% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
}
img.connet {
  width: 50px;
  margin: 0 10px;
}
.info {
  width: 979px;
  height: 54px;
  font-size: 22px;
  font-family: Graphie;
  font-weight: 600;
  line-height: 28px;
  color: #28327b;
  padding-top: 100px;
  margin: 0 auto;
}
.video {
  margin: 0 auto;
  margin-top: 200px;
  padding-bottom: 150px;
  position: relative;
  .video-title {
    margin: 0 auto;
    width: 644px;
    height: 92px;
    background: #f15922;
    border: 5px solid #ffffff;
    transform: skew(-15deg);
    position: absolute;
    top: -10%;
    left: 50%;
    margin-left: -322px;
    .video-title1 {
      font-size: 34px;
      font-weight: 600;
      line-height: 92px;
      color: #ffffff;
      text-align: center;
      transform: skew(13deg);
    }
  }
  .video-pic {
    margin: 0 auto;
    background: url('../assets/img/1-Box-Images/images_video.png') 100%/100%;
    width: 90%;
    height: 614px;
    border-radius: 8px;
  }
}
</style>

<style lang="scss">
#CoachAction {
  .swiper-pagination {
    text-align: right !important;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    top: 15px;
    right: 45px;
    left: unset !important;
    width: 30%;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 6px !important;
      display: inline-block;
      background: #fff;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: #f15922;
      opacity: 1;
    }
  }
}
@media screen and (max-width: 480px) {
  #CoachAction {
    .swiper-container-horizontal > .swiper-pagination-bullets {
      top: 15px;
      right: 4%;
      .swiper-pagination-bullet {
        width: 14px;
        height: 14px;
        border-radius: 50% !important;
      }
    }
    .swiper {
      height: 500px;
      .swiper-item1 {
        .top-right-con {
          width: 40.5%;
          height: 14%;
          transform: skew(-15deg) translateX(7%);
        }
        .box {
          height: 100%;
          width: 53%;
          .img {
            height: 100%;
            width: 120%;
            right: 0%;
          }
        }
      }
    }

    .course-video {
      position: static;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 40px;
      .left,
      .right {
        .tit-box {
          p {
            top: 28%;
            left: 0 !important;
            width: 100%;
            font-size: 30px !important;
          }
        }
        .bottom-con {
          height: 20px !important;
        }
      }
      .left {
        width: 100%;
        height: 408px;
        margin-bottom: 2%;
        transform: none;
        overflow: hidden;
        .img-box {
          height: 100%;
          transform: none;
          .img-bg {
            width: 100%;
            height: 100%;
            transform: none;
          }
        }
        .tit-box {
          width: 55%;
          p {
            top: 20%;
            left: 20%;
            font-size: 18px;
          }
        }
        .mantle {
          transform: none;
        }
        .img {
          width: 49%;
        }
        .bottom-con {
          height: 8px;
          width: 100%;
          transform: none;
        }
      }
      .right {
        position: static;
        width: 100%;
        height: 408px;
        transform: translateX(0%);
        .img-box {
          height: 100%;
          width: 100%;
          transform: none;
          .img-bg {
            width: 100%;
            height: 100%;
            transform: none;
          }
        }
        .tit-box {
          width: 55%;
          p {
            top: 20%;
            left: 20%;
            font-size: 18px;
          }
        }
        .bottom-con {
          height: 8px;
          transform: none;
        }
        .mantle {
          transform: none;
        }
        //background: url(../../assets/images/啟發篇4-2.jpg) no-repeat 100%/110%;
        .img {
          width: 47%;
        }
      }
    }

    .top-left-con {
      width: 76%;
      height: 368px;
      top: 0;
      left: -16%;
      .bg-tit1 {
        font-size: 35px !important;
        margin-top: 15% !important;
        padding-left: 30%;
      }
      .bg-tit2 {
        margin-top: 2% !important;
        padding-left: 30% !important;
        font-size: 35px !important;
        padding-left: 0;
      }
      .more {
        font-size: 20px;
        bottom: 12px;
        right: 6px;
      }
      .line-two {
        width: 14px;
        top: 33%;
      }
      .line-one {
        width: 60px;
        top: 20%;
      }
    }
    .bottom-left-con {
      width: 48%;
      height: 30%;
      left: -2%;
    }
    .tit1 {
      width: 90%;
      margin: 50px auto;
      font-size: 32px;
      text-align: left;
      letter-spacing: 1px;
    }
    .tit2 {
      width: 90%;
    }
    .list-scroll {
      width: 100%;
      margin: 0% auto;
      overflow: hidden;
      overflow-x: auto;
      .list-bg {
        width: 1100px;
        padding: 0 6%;
      }
    }
  }
}
</style>
